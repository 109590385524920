import React, {useState, useEffect} from 'react';
import Message from './Message';
import './App.css';


function Iphone({date, lieu, espaces, invites}) {
    const [time, setTime] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setTime((new Date()).toLocaleTimeString("fr-FR", {hour: '2-digit', minute: '2-digit'}));
        });

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatDate = (inputDate) => {
        if (inputDate === '\u2001') {
            return '\u2001';
        }
        // Convert date from dd/mm/yyyy to mm/dd/yyyy
        const [day, month, year] = inputDate.split('/');
        const convertedDate = `${month}/${day}/${year}`;

        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(convertedDate).toLocaleDateString('fr-FR', options);
    };

    return (<div id="iphone">
        <div id="center">
            <div id="msg">
                <div id="msg-top">
                    <div className="col-md-6 col-xs-6 text-center">
                        <div className="pull-left" id="clock">
                <span className="result" id="clock-result">
                  {time}
                </span>
                        </div>
                    </div>
                    <div className="col-md-6 col-xs-6 text-center">
                        <div className="pull-right">
                            <div id="battery">
                                <div id="battery-percent"></div>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-12 col-xs-12 text-center" id="txt-name-container">
                        <div className="" id="txt-name">
                            <img height="50px" src="/images/logo_cocarde_no_background.png" alt={"logo JT"}/>
                            <span className="result" id="name-result">
                  Le Jockey Tricolore
                </span>
                        </div>
                    </div>
                </div>
                <div id="msgs-wrapper">
                    <ul className="ui-sortable" id="msgs">
                        <Message text="Connaissez-vous la date de votre événement ?"/>
                        <Message text={formatDate(date)} green={true}/>
                        <Message text="Ainsi que le lieu ?"/>
                        <Message text={lieu} green={true}/>
                        <Message text="Quel est le nombre d'espaces à sonoriser ?"/>
                        <Message text={espaces} green={true}/>
                        <Message text="Combien de convives attendez-vous ?"/>
                        <Message text={invites} green={true}/>
                        <Message text="Le Jockey Tricolore accepte votre mission !"/>
                    </ul>
                </div>
                <div id="msgs-bottom">
                    <div id="text-textmessage-result">Merci !!</div>
                    <div id="text-send-result" style={{display: 'block'}}>
                        Envoyer
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Iphone;