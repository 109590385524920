import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import Iphone from './Iphone';


function App() {
    const [date, setDate] = useState('');
    const [lieu, setLieu] = useState('');
    const [espaces, setEspaces] = useState('');
    const [invites, setInvites] = useState('');

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setDate(searchParams.get('date'));
        setLieu(searchParams.get('lieu'));
        setEspaces(searchParams.get('espaces'));
        setInvites(searchParams.get('invites'));
    }, [location.search]);


    return (<Iphone date={date} lieu={lieu} espaces={espaces} invites={invites}/>);
}

export default App;
