import React from 'react';

function Message({ text, green }) {
    const messageClassName = green ? 'msg green ui-sortable-handle' : 'msg ui-sortable-handle';

    return (
        <li className={messageClassName}>
            <div className="content">
                <p>{text}</p>
            </div>
            <div className="sep"></div>
        </li>
    );
}

export default Message;
